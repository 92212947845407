/* eslint-disable no-underscore-dangle */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-param-reassign */
import { Cookies } from 'react-cookie'
import { COOKIES_OPTIONS } from '_/utils/constants'
import axios from 'axios'
import { API_URL } from '_/config/environment'

const cookies = new Cookies()

const { NODE_ENV } = process.env

const isDevelopment = NODE_ENV === 'development'
const shouldPrintLogsToConsole = false // Set to true to enable dev environment logs

function printLog(...logs) {
  if (shouldPrintLogsToConsole && isDevelopment)
    setTimeout(() => console.info('[RefreshTokenInterceptor]', ...logs), 0)
}

function parseBlobToJson(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      try {
        const jsonObject = JSON.parse(reader.result)
        resolve(jsonObject)
      } catch (error) {
        reject(error)
      }
    }

    reader.onerror = () => {
      reject(reader.error)
    }

    reader.readAsText(blob)
  })
}

let failedQueue = []
let isRefreshing = false

const setInterceptor = (axiosInstance, onRefreshError, storeTokenInLocalStateFn) => {
  printLog('--- Setting up axios interceptor for token refresh ---', { axiosInstance })

  axiosInstance.interceptors.response.use(
    response => response,
    async requestError => {
      const originalRequestConfig = requestError.config

      // Prevent retry loop
      if (originalRequestConfig._retry) {
        printLog('--- Request retry already attempted. Rejecting ---', { originalRequestConfig })
        return Promise.reject(requestError)
      }

      // Check if the error is due to an expired token
      let errorCode

      if (requestError.response?.data) {
        // Check if the error is a Blob or a JSON object, in case the request error data
        // is a Blob
        errorCode =
          requestError.response.data instanceof Blob
            ? (await parseBlobToJson(requestError.response.data))?.code
            : requestError.response.data?.code
      }

      if (requestError.response?.status === 401 && errorCode === 'token_not_valid') {
        printLog('--- Unauthorized. Looking for Refresh Token ---', {
          requestError: JSON.parse(JSON.stringify(requestError)),
        })
        const refreshToken = cookies.get(COOKIES_OPTIONS.refreshTokenName, {
          path: COOKIES_OPTIONS.path,
        })

        if (!refreshToken) {
          printLog('--- Refresh Token Not Found. Rejecting ---', {
            requestError: JSON.parse(JSON.stringify(requestError)),
          })
          axiosInstance.defaults.headers.common.Authorization = undefined
          onRefreshError()
          return Promise.reject(requestError)
        }

        if (isRefreshing) {
          printLog('--- Token already being refreshed. Adding request to queue ---', {
            requestError: JSON.parse(JSON.stringify(requestError)),
          })
          return new Promise((resolve, reject) => {
            failedQueue.push({
              onSuccess: token => {
                printLog('--- Token refreshed. Retrying request ---', { originalRequestConfig })
                originalRequestConfig.headers.Authorization = `Bearer ${token}`
                resolve(axiosInstance(originalRequestConfig))
              },
              onFailure: error => {
                printLog('--- Token refresh failed. Rejecting request ---', { error })
                reject(error)
              },
            })
          })
        }

        originalRequestConfig._retry = true
        isRefreshing = true

        return new Promise(async (resolve, reject) => {
          try {
            printLog('--- Refreshing Token ---', { previousRefreshToken: refreshToken })
            const { data } = await axios.post(`${API_URL}/token/refresh/`, {
              refresh: refreshToken,
            })

            printLog('--- Token Refreshed ---', data)

            cookies.set(COOKIES_OPTIONS.authTokenName, data.access, {
              path: COOKIES_OPTIONS.path,
            })

            cookies.set(COOKIES_OPTIONS.refreshTokenName, data.refresh, {
              path: COOKIES_OPTIONS.path,
            })

            if (storeTokenInLocalStateFn) storeTokenInLocalStateFn(data.access)

            if (originalRequestConfig.data) {
              originalRequestConfig.data = JSON.parse(originalRequestConfig.data)
            }

            originalRequestConfig.headers.Authorization = `Bearer ${data.access}`
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.access}`

            printLog('--- Retrying requests ---', { failedQueue })
            failedQueue.forEach(request => {
              request.onSuccess(data.access)
            })

            printLog('--- Resolving request ---', { originalRequestConfig })

            resolve(axiosInstance(originalRequestConfig))
          } catch (error) {
            printLog('--- Token Refresh Failed ---', { error })
            failedQueue.forEach(request => {
              request.onFailure(error)
            })

            axiosInstance.defaults.headers.common.Authorization = undefined
            onRefreshError()
            reject(error)
          } finally {
            isRefreshing = false
            failedQueue = []
          }
        })
      }

      return Promise.reject(requestError)
    }
  )
}

export function attachRefreshTokenInterceptor({
  axiosInstanceList,
  onRefreshError,
  storeTokenInLocalStateFn,
}) {
  axiosInstanceList.forEach(axiosInstance => {
    setInterceptor(axiosInstance, onRefreshError, storeTokenInLocalStateFn)
  })
}
